:local(.statusbar) {
    display: block;
    height: 10px;
    width: 100px;
    background-color: #fe0400;
    @media screen and (max-width: 600px) {
        display: none;
    }
}
:local(.block) {
    background-color:#589d43;
    width: 20px;
    height: 10px;
}
:local(.svgblock){
    display: flex;
    justify-content: space-evenly;
    svg {
        margin: 5px;
    }
}
:local(table td) {
    vertical-align: top;
} 

