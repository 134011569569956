.redux-toastr .toastr {
   &:hover {
      opacity: 0.94 !important;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4) !important;
   }

   &:focus {
      outline: 0;
   }

   .close-toastr {
      margin-right: 6px;
   }

   .rrt-middle-container .rrt-title {
      font-size: 16px !important;
      font-weight: normal !important;
      line-height: 50px;
      margin: 0 !important;
   }
}


gn-accordion {
   margin: 20px 0;
}

.wmde-markdown {
   background-color: transparent;
   font-family: "Open Sans",sans-serif;
   font-size: 14px;
}
