.form-control,
.rbt-input,
.rbt-input-hint {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    background: none;
    color: #000;
    font-size: 12pt;
    padding: 0 8px;
    height: 34px;
    min-height: 34px;
    border: 1px solid #cfcfcf;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    z-index: 1;
    width: 100%;
    &:disabled {
        background-color: #eee;
    }
}

.rbt-input-hint {
    color: #777;
}

.rbt-input-multi.form-control {
    padding: 3px 8px;
}

.dropdown-menu {
    padding: 0.5rem 0;
    border: 1px solid #cfcfcf;
    background-color: #fff;
    z-index: 2;
    width: 100%;
    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1rem;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        color: #000;
    }
}

.rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.rbt-token {
    background-color: #4c7aa9;
    border-radius: 4px;
    color: #fff;
    padding: 5px 21px 5px 8px;
    position: relative;
    .rbt-token-label {
        padding: 0;
    }
    .rbt-token-remove-button {
        bottom: 0;
        padding: 0 8px;
        position: absolute;
        right: 0;
        background-color: transparent;
        border: 0;
        font-size: 24px;
    }
}
